import { DeepMap, FieldError } from 'react-hook-form-deprecated'

export enum DiscoverFormFields {
  TITLE = 'title',
  FRIENDS = 'friends'
}

export interface Friend {
  slug: string
  displayName?: string
  childLinkId?: string
}
export type Friends = Friend[]

export interface DiscoverForm {
  friends: Friends
  title: string
}
export interface FriendInputProps {
  slug: string
  canDelete: boolean
  displayName?: string
  updateVals: (val: string, idx: number, isSlug?: boolean) => void
  errors: DeepMap<{ friends: any }, FieldError>
  idx: number
  id: string
  onDelete: (idx: number) => void
}

export interface FriendWithImage extends Friend {
  image: string | null
}
