import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { BoxProps } from '@dtx-company/shared-components/src'
import { Button } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { DefaultManager, ManagerProps } from '../components/Manager/Manager'
import { DiscoverForm, DiscoverFormFields, FriendWithImage, Friends } from './types'
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'
import { EditLinkCardEndAdornment } from '../EditLinkUtils'
import { FC, useEffect, useMemo } from 'react'
import { FlowpageProps } from '../types/FlowpageProps'
import { FormProps, StyledForm } from '../components/Layout'
import { FormTitle } from '../components/Title'
import {
  FriendDisplay,
  FriendInput,
  MAX_FRIENDS,
  useGetFriendImages,
  useValidationSchema
} from './utils'
import { LinkStyleGated } from '../components/LinkStyle/LinkStyleGated'
import { LinkType } from '../types'
import { NextLinkWrap } from '../../util/NextLinkUtils'
import { SettingsAndSaveLink } from '../components/Settings'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { TitleInput } from '../components/Inputs'
import { TopFriends as TopFriendsIcon } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/TopFriends'
import { UseFormReturn, useForm } from 'react-hook-form'
import { defaultWidgetLink } from '../../../constants'
import { getLinkStyleValuesFromTheme } from '../../../components/flowpage/FlowpageLinkCard/utils/getLinkStyleValuesFromTheme'
import { getValidatedActionData, getValidatedActionDataOrThrow } from '../typeUtils'
import { moveListItem } from '../../../utils/object'
import { removeNull } from '@dtx-company/true-common/src/utils/objects'
import { useFlowpageAnalyticsCollector } from '../../../hooks/useFlowpageAnalyticsCollector'
import { useFlowpageTheme } from '../../../components/profile/Customize/hooks'
import { useLinkThemeState } from '../components/LinkStyle/hooks/useLinkThemeState'
import { useSetValueWithPreview } from '../utils'
import { useSubmitWidget } from '../submitUtils'
import { v4 as uuid } from 'uuid'
import { yupResolver } from '@hookform/resolvers/yup'
import clone from 'lodash/clone'

export const Form: FC<FormProps> = ({ order, curr, handleClose }: FormProps) => {
  const id = curr?.id || uuid()

  const { linkTheme, setLinkTheme } = useLinkThemeState({
    defaultLinkTheme: curr?.linkTheme ?? null
  })
  const actionData = getValidatedActionData<'discover'>(curr?.actionData, 'friends')
  const validationSchema = useValidationSchema()
  const {
    register,
    watch,
    formState: { errors },
    setValue: setFormValue,
    handleSubmit,
    trigger
  } = useForm({
    defaultValues: {
      friends: actionData?.friends || [{ slug: '', displayName: '' }],
      title: curr?.title ?? ''
    },
    resolver: yupResolver(validationSchema)
  })
  const submitWidget = useSubmitWidget()
  const onSubmit = async ({ title, friends }: DiscoverForm): Promise<void> => {
    await submitWidget({
      curr,
      actionData: { friends },
      widgetType: 'discover',
      handleClose,
      linkTheme,
      fields: {
        id,
        description: '',
        order,
        title
      }
    })
  }

  const { friends: curFriends, title: curTitle } = watch()
  const hasRemainingFriends = MAX_FRIENDS - Object.keys(curFriends).length > 0

  const previewLink = useMemo(
    () => ({
      ...defaultWidgetLink,
      id,
      title: curTitle,
      type: 'discover',
      actionData: { friends: curFriends },
      linkTheme
    }),
    [id, curFriends, curTitle, linkTheme]
  )

  const { setValue, setLinkThemeValue } = useSetValueWithPreview(
    previewLink,
    setFormValue,
    setLinkTheme
  )
  const addFriend = (): void => {
    setValue(DiscoverFormFields.FRIENDS, [...curFriends, { slug: '', displayName: '' }])
  }

  const deleteFriend = (idx: number): void => {
    const newFriends = clone(curFriends)
    newFriends.splice(idx, 1)
    setValue(DiscoverFormFields.FRIENDS, newFriends)
  }

  const updateFriends = async (val: string, idx: number, isSlug?: boolean): Promise<void> => {
    const updated = clone(curFriends)
    const newVals = {
      ...curFriends[idx],
      ...(isSlug ? { slug: val?.toLowerCase() } : { displayName: val })
    }
    updated?.splice(idx, 1, newVals)
    setValue(DiscoverFormFields.FRIENDS, updated)
    const newFriends = `friends.${idx}.${isSlug ? 'slug' : 'displayName'}` as
      | `friends.${number}.slug`
      | `friends.${number}.displayName`
    await trigger(newFriends)
  }

  const reorderFriends = (result: DropResult): void => {
    if (!result.destination || result.destination.index === result.source.index) {
      return
    }
    const updated = moveListItem(curFriends, result.source.index, result.destination?.index)
    setValue(DiscoverFormFields.FRIENDS, updated)
  }

  useEffect(() => {
    register(DiscoverFormFields.TITLE)
    register(DiscoverFormFields.FRIENDS)
  }, [register])
  const edit = Boolean(curr)
  return (
    <>
      <StyledForm
        title={
          <FormTitle
            icon={<TopFriendsIcon />}
            title={`${edit ? 'Edit your' : 'Add your'} top friends`}
          />
        }
        onSubmit={handleSubmit(onSubmit)}
      >
        <TitleInput
          value={removeNull(curTitle) || ''}
          errors={errors}
          onChange={e =>
            setValue(DiscoverFormFields.TITLE, e.target.value, { shouldValidate: true })
          }
        />
        <DragDropContext onDragEnd={reorderFriends}>
          <Droppable droppableId="friendList">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {Object.values(curFriends).map((friend, idx) => (
                  <FriendInput
                    canDelete={Object.keys(curFriends).length > 1}
                    idx={idx}
                    id={id + idx}
                    // friends list is not unique
                    key={friend.slug + idx}
                    errors={errors}
                    slug={friend.slug}
                    displayName={friend?.displayName}
                    onDelete={deleteFriend}
                    updateVals={updateFriends}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {hasRemainingFriends && (
          <Button
            minWidth="200px"
            sizeVariant="large"
            colorVariant="secondaryBlack"
            onClick={addFriend}
          >
            <Text fontSize={0} fontWeight={600}>
              Add another friend
            </Text>
          </Button>
        )}
        <Spacer mb="16px" />

        <Spacer mb="16px" />
        <LinkStyleGated
          hiddenFields={{
            borderColor: true,
            shadowColor: true,
            linkColor: true
          }}
          linkTheme={linkTheme}
          setLinkTheme={setLinkThemeValue}
        />

        <SettingsAndSaveLink disabled={false} curr={curr} handleClose={handleClose} />
      </StyledForm>
    </>
  )
}

const TopFriends: FC<{
  title?: string | null
  friends: Friends
  preview?: boolean
  linkId: string
  editLinkMode?: boolean
  empty?: boolean
  trigger?: UseFormReturn['trigger']
  linkTheme?: LinkType['linkTheme']
}> = ({
  title,
  empty = false,
  friends,
  preview = false,
  editLinkMode = false,
  linkId,
  trigger,
  linkTheme
}) => {
  const { trackLinkClick } = useFlowpageAnalyticsCollector({
    isPreview: preview,
    isEditMode: editLinkMode
  })
  const displayTopFriendsChildLinks = !preview
  const filteredFriends = useGetFriendImages(friends, empty, preview, trigger)
  const { theme } = useFlowpageTheme()
  return (
    <Box
      alignItems="center"
      color={editLinkMode ? 'primary.black' : 'flowpage.contrastText'}
      flexDirection="column"
      margin="auto"
    >
      <Text mb="16px" color="inherit" variant="body/large">
        {title}
      </Text>
      <Box flexWrap="wrap" justifyContent="center">
        {filteredFriends.map((friend: FriendWithImage, i) => {
          const childLinkId = displayTopFriendsChildLinks ? friend.childLinkId : linkId
          const parentLinkId = displayTopFriendsChildLinks ? linkId : undefined
          const { fontColor, titleFontSize } = getLinkStyleValuesFromTheme(theme, linkTheme)

          const props = {
            alignItems: 'center',
            // friend list is not unique
            key: friend.slug + i,
            margin: '4px',
            flexDirection: 'column',
            color: fontColor ?? 'flowpage.contrastText',
            fontSize: `${titleFontSize}pt`
          } as Omit<BoxProps, 'display'>

          return preview || editLinkMode ? (
            <Box {...props}>
              <FriendDisplay editLinkMode={editLinkMode} friend={friend} preview={preview} i={i} />
            </Box>
          ) : (
            <NextLinkWrap
              data-testid={`${friend.slug}-friend-link`}
              onClick={() => {
                trackLinkClick(
                  { link_id: childLinkId, parent_link_id: parentLinkId },
                  { useHeap: true }
                )
              }}
              cursor="pointer"
              display="flex"
              href={'/page/' + friend.slug}
              {...props}
            >
              <FriendDisplay i={i} friend={friend} preview={preview} />
            </NextLinkWrap>
          )
        })}
      </Box>
    </Box>
  )
}

export const Flowpage: FC<FlowpageProps> = ({
  link,
  preview,
  editLinkMode,
  isLockedTemplateLink
}: FlowpageProps) => {
  const actionData = getValidatedActionDataOrThrow<'discover'>(link?.actionData, 'friends')
  const friendsArray = !preview
    ? (link.childLinks ?? []).map(child => {
        return { ...child?.actionData, childLinkId: child?.id }
      })
    : actionData?.friends
  return (
    <Box width="100%">
      <TopFriends
        empty={actionData?.empty}
        friends={friendsArray}
        preview={preview}
        editLinkMode={editLinkMode}
        title={link.title}
        linkId={link.id}
        linkTheme={link.linkTheme}
      />
      {editLinkMode && <EditLinkCardEndAdornment isLockedTemplateLink={isLockedTemplateLink} />}
    </Box>
  )
}

export const Manager: FC<ManagerProps> = ({ link, editWidget, key, handle }: ManagerProps) => {
  return (
    <DefaultManager
      key={key || link.title || 'Top Friends'}
      link={link}
      altTitle={'Top Friends'}
      handle={handle}
      iconUrl="/static/icons/avatar-black.svg"
      editWidget={editWidget}
    />
  )
}
