import * as Yup from 'yup'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Button } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { Draggable, DraggableProvided } from 'react-beautiful-dnd'
import { Friend, FriendInputProps, FriendWithImage, Friends } from './types'
import { GET_UNAUTH_PROFILE_IMAGE, SLUG_RESERVED } from '@dtx-company/flow-codegen/src/page/queries'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import {
  SlugReservedQuery,
  UnauthPageImageQuery,
  UnauthPageImageQueryVariables
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { UseFormMethods } from 'react-hook-form-deprecated'
import { bioPlaceholder } from '../../../assets/placeholders'
import { flowpageRoot } from '../../../constants'
import { pageGqlFetcher } from '@dtx-company/inter-app/src/services/gqlFetcher'

import { FC, useEffect, useState } from 'react'
import { useDraggableInPortal } from '../../../utils/hooks'
import get from 'lodash/get'

export const MAX_FRIENDS = 5
export const FriendInput: FC<FriendInputProps> = ({
  slug,
  displayName,
  updateVals,
  id,
  onDelete,
  canDelete,
  errors,
  idx
}: FriendInputProps) => {
  const slugError = get(errors, `friends[${idx}].slug`)
  const nameError = get(errors, `friends[${idx}].displayName`)
  const renderDraggable = useDraggableInPortal()
  return (
    <Draggable draggableId={id} index={idx}>
      {renderDraggable((provided: DraggableProvided) => (
        <div ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
          <Box mb="8px" borderLeft="2px solid #dadada" pl={16} flexDirection="column">
            <Input
              type="text"
              labelProps={{ marginBottom: '6px' }}
              error={Boolean(nameError)}
              helperText={nameError?.message}
              value={displayName || ''}
              maxWidth="100%"
              placeholder="Name"
              label={`Friend ${idx + 1} Display Name`}
              onChange={e => {
                updateVals(e.target.value, idx)
              }}
              mb="4px"
            />
            <Input
              startAdornment={<Text ml="16px">{flowpageRoot}</Text>}
              type="text"
              labelProps={{ marginBottom: '6px' }}
              error={Boolean(slugError)}
              helperText={slugError?.message}
              value={slug}
              placeholder="myfriend"
              maxWidth="100%"
              label={`Friend ${idx + 1} Link`}
              onBlur={e => {
                updateVals(e.target.value, idx, true)
              }}
            />
            <Button
              mt="8px"
              sizeVariant="large"
              maxHeight={24}
              minWidth="120px"
              disabled={!canDelete}
              colorVariant="primaryBlack"
              onClick={() => onDelete(idx as 1 | 2 | 3 | 4)}
            >
              Delete Friend
            </Button>
          </Box>
        </div>
      ))}
    </Draggable>
  )
}

export const useGetFriendImages = (
  friends: Friends,
  empty = false,
  preview: boolean,
  trigger?: UseFormMethods['trigger']
): FriendWithImage[] => {
  const [filteredFriends, setFilteredFriends] = useState<FriendWithImage[]>([])

  useEffect(() => {
    const getFriendImage = async (friend: Friend, i: number): Promise<FriendWithImage> => {
      if (!empty) {
        try {
          if (
            preview &&
            (await pageGqlFetcher<SlugReservedQuery>(SLUG_RESERVED, { slug: friend.slug }))
              .slugReserved
          ) {
            trigger?.(`friends[${i}].slug`)
          }
          const { unauthPage } = await pageGqlFetcher<
            UnauthPageImageQuery,
            UnauthPageImageQueryVariables
          >(GET_UNAUTH_PROFILE_IMAGE, {
            slug: friend.slug
          })

          return { ...friend, image: unauthPage?.profileImage || null }
        } catch (e) {
          console.error(e)
        }
      }
      return { ...friend, image: null }
    }
    const getData = async (): Promise<FriendWithImage[]> => {
      return Promise.all(Object?.values(friends).map((friend, i) => getFriendImage(friend, i)))
    }
    getData().then(data => setFilteredFriends(data))
  }, [empty, friends, preview, trigger])
  return filteredFriends
}

export function useValidationSchema(): Yup.ObjectSchema<{
  friends?: Array<{
    displayName?: string | null
    slug: string
  }>
}> {
  return Yup.object({
    friends: Yup.array().of(
      Yup.object({
        displayName: Yup.string().nullable(),
        slug: Yup.string()
          .required('Link is required')
          .test('slug', 'Flowpage does not exist', async function (this, value) {
            const { createError, path } = this

            if (
              (await pageGqlFetcher<SlugReservedQuery>(SLUG_RESERVED, { slug: value })).slugReserved
            ) {
              return true
            }
            throw createError({ path, message: 'Flowpage does not exist' })
          })
      })
    )
  })
}

export const FriendDisplay: FC<{
  friend: FriendWithImage
  preview?: boolean
  i: number
  editLinkMode?: boolean
}> = ({ friend, editLinkMode = false }) => (
  <>
    <Image
      borderRadius="50%"
      alt={friend.slug}
      width={editLinkMode ? 60 : 100}
      height={editLinkMode ? 60 : 100}
      bg="secondary.border"
      padding={friend.image ? 0 : 20}
      src={friend.image || bioPlaceholder}
    />
    <Spacer mb="8px" />
    <Text color="inherit" fontSize="inherit">
      {friend.displayName}
    </Text>
  </>
)
